import axios from '@/common/axios'
import qs from 'qs'

export function addStabilityTestWorkPlan (data) {
  return axios({
    method: 'post',
    url: '/quality/stabilityTestPlan/add',
    data: qs.stringify(data)
  })
}

export function deleteStabilityTestWorkPlan (id) {
  return axios({
    method: 'delete',
    url: '/quality/stabilityTestPlan/delete/' + id
  })
}

export function updateStabilityTestWorkPlan (data) {
  return axios({
    method: 'put',
    url: '/quality/stabilityTestPlan/update',
    data: qs.stringify(data)
  })
}

export function selectStabilityTestWorkPlanInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/stabilityTestPlan/info/' + id
  })
}

export function selectStabilityTestWorkPlanList (query) {
  return axios({
    method: 'get',
    url: '/quality/stabilityTestPlan/list',
    params: query
  })
}

export function reviewStabilityTestWorkPlan (data) {
  return axios({
    method: 'put',
    url: '/quality/stabilityTestPlan/updateReview',
    data: qs.stringify(data)
  })
}
