<template>
  <div id="stabilityTestWorkPlan">
    <el-dialog
      :title="stabilityTestWorkPlanFormTitle"
      width="800px"
      :visible.sync="stabilityTestWorkPlanDialogVisible"
      :close-on-click-modal="false"
      @close="stabilityTestWorkPlanDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="stabilityTestWorkPlanFormRef"
        :model="stabilityTestWorkPlanForm"
        :rules="stabilityTestWorkPlanFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="品名" prop="productName">
              <el-input
                v-model="stabilityTestWorkPlanForm.productName"
                placeholder="请输入品名"
                clearable
                :disabled="stabilityTestWorkPlanFormTitle !== '新增稳定性试验工作计划'
                  && stabilityTestWorkPlanFormTitle !== '修改稳定性试验工作计划'
                  && stabilityTestWorkPlanFormTitle !== '稳定性试验工作计划详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计划时间" prop="planTime">
              <el-date-picker
                v-model="stabilityTestWorkPlanForm.planTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :disabled="stabilityTestWorkPlanFormTitle !== '新增稳定性试验工作计划'
                  && stabilityTestWorkPlanFormTitle !== '修改稳定性试验工作计划'
                  && stabilityTestWorkPlanFormTitle !== '稳定性试验工作计划详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNo">
              <el-input
                v-model="stabilityTestWorkPlanForm.batchNo"
                placeholder="请输入批号"
                clearable
                :disabled="stabilityTestWorkPlanFormTitle !== '新增稳定性试验工作计划'
                  && stabilityTestWorkPlanFormTitle !== '修改稳定性试验工作计划'
                  && stabilityTestWorkPlanFormTitle !== '稳定性试验工作计划详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格" prop="spec">
              <el-input
                v-model="stabilityTestWorkPlanForm.spec"
                placeholder="请输入规格"
                clearable
                :disabled="stabilityTestWorkPlanFormTitle !== '新增稳定性试验工作计划'
                  && stabilityTestWorkPlanFormTitle !== '修改稳定性试验工作计划'
                  && stabilityTestWorkPlanFormTitle !== '稳定性试验工作计划详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考察项目及方法" prop="projectMethod">
              <el-input
                v-model="stabilityTestWorkPlanForm.projectMethod"
                placeholder="请输入考察项目及方法"
                clearable
                :disabled="stabilityTestWorkPlanFormTitle !== '新增稳定性试验工作计划'
                  && stabilityTestWorkPlanFormTitle !== '修改稳定性试验工作计划'
                  && stabilityTestWorkPlanFormTitle !== '稳定性试验工作计划详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实施部门" prop="dept">
              <el-input
                v-model="stabilityTestWorkPlanForm.dept"
                placeholder="请输入实施部门"
                clearable
                :disabled="stabilityTestWorkPlanFormTitle !== '新增稳定性试验工作计划'
                  && stabilityTestWorkPlanFormTitle !== '修改稳定性试验工作计划'
                  && stabilityTestWorkPlanFormTitle !== '稳定性试验工作计划详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="质量部负责人意见" prop="opinions">
              <el-input
                v-model="stabilityTestWorkPlanForm.opinions"
                placeholder="请输入质量部负责人意见"
                clearable
                :disabled="stabilityTestWorkPlanFormTitle !== '稳定性试验工作计划质量部负责人意见'
                  && stabilityTestWorkPlanFormTitle !== '稳定性试验工作计划详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核结果" prop="reviewResult">
              <el-radio-group
                v-model="stabilityTestWorkPlanForm.reviewResult"
                :disabled="stabilityTestWorkPlanFormTitle !== '稳定性试验工作计划质量部负责人意见'
                  && stabilityTestWorkPlanFormTitle !== '稳定性试验工作计划详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="stabilityTestWorkPlanDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="stabilityTestWorkPlanFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="stabilityTestWorkPlanFormTitle === '稳定性试验工作计划详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="批号">
        <el-input v-model="searchForm.batchNo" placeholder="请输入批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['STABILITY_TEST_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="stabilityTestWorkPlanPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="productName" label="品名" />
      <el-table-column prop="planTime" label="计划时间" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column prop="projectMethod" label="考察项目及方法" />
      <el-table-column prop="dept" label="实施部门" />
      <el-table-column prop="planner" label="计划人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.plannedDate">{{ scope.row.plannedDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="opinions" label="质量部负责人意见" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.headerDate">{{ scope.row.headerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['STABILITY_TEST_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['STABILITY_TEST_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['STABILITY_TEST_DIRECTOR']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            质量部负责人意见
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="stabilityTestWorkPlanPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addStabilityTestWorkPlan,
  deleteStabilityTestWorkPlan,
  updateStabilityTestWorkPlan,
  selectStabilityTestWorkPlanInfo,
  selectStabilityTestWorkPlanList,
  reviewStabilityTestWorkPlan
} from '@/api/quality/stabilityTestWorkPlan'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      stabilityTestWorkPlanDialogVisible: false,
      stabilityTestWorkPlanFormTitle: '',
      stabilityTestWorkPlanForm: {
        id: '',
        productName: '',
        planTime: '',
        batchNo: '',
        spec: '',
        projectMethod: '',
        dept: '',
        opinions: '',
        reviewResult: '',
        status: '',
        taskId: ''
      },
      stabilityTestWorkPlanFormRules: {
        batchNo: [{ required: true, message: '批号不能为空', trigger: ['blur', 'change']}]
      },
      stabilityTestWorkPlanPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        batchNo: ''
      }
    }
  },
  created () {
    selectStabilityTestWorkPlanList(this.searchForm).then(res => {
      this.stabilityTestWorkPlanPage = res
    })
  },
  methods: {
    stabilityTestWorkPlanDialogClose () {
      this.$refs.stabilityTestWorkPlanFormRef.resetFields()
    },
    stabilityTestWorkPlanFormSubmit () {
      if (this.stabilityTestWorkPlanFormTitle === '稳定性试验工作计划详情') {
        this.stabilityTestWorkPlanDialogVisible = false
        return
      }
      this.$refs.stabilityTestWorkPlanFormRef.validate(async valid => {
        if (valid) {
          this.stabilityTestWorkPlanForm.planTime = this.stabilityTestWorkPlanForm.planTime.join('至')
          if (this.stabilityTestWorkPlanFormTitle === '新增稳定性试验工作计划') {
            this.stabilityTestWorkPlanForm.id = ''
            this.stabilityTestWorkPlanForm.status = 1
            await addStabilityTestWorkPlan(this.stabilityTestWorkPlanForm)
          } else if (this.stabilityTestWorkPlanFormTitle === '修改稳定性试验工作计划') {
            await updateStabilityTestWorkPlan(this.stabilityTestWorkPlanForm)
          } else if (this.stabilityTestWorkPlanFormTitle === '稳定性试验工作计划质量部负责人意见') {
            this.stabilityTestWorkPlanForm.status = 2
            await reviewStabilityTestWorkPlan(this.stabilityTestWorkPlanForm)
          }
          this.stabilityTestWorkPlanPage = await selectStabilityTestWorkPlanList(this.searchForm)
          this.stabilityTestWorkPlanDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.stabilityTestWorkPlanFormTitle = '新增稳定性试验工作计划'
      this.stabilityTestWorkPlanDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteStabilityTestWorkPlan(row.id)
        if (this.stabilityTestWorkPlanPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.stabilityTestWorkPlanPage = await selectStabilityTestWorkPlanList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.stabilityTestWorkPlanFormTitle = '修改稳定性试验工作计划'
      this.stabilityTestWorkPlanDialogVisible = true
      this.selectStabilityTestWorkPlanInfoById(row.id)
    },
    handleReview (index, row) {
      this.stabilityTestWorkPlanFormTitle = '稳定性试验工作计划质量部负责人意见'
      this.stabilityTestWorkPlanDialogVisible = true
      this.selectStabilityTestWorkPlanInfoById(row.id)
    },
    handleInfo (index, row) {
      this.stabilityTestWorkPlanFormTitle = '稳定性试验工作计划详情'
      this.stabilityTestWorkPlanDialogVisible = true
      this.selectStabilityTestWorkPlanInfoById(row.id)
    },
    selectStabilityTestWorkPlanInfoById (id) {
      selectStabilityTestWorkPlanInfo(id).then(res => {
        this.stabilityTestWorkPlanForm.id = res.id
        this.stabilityTestWorkPlanForm.productName = res.productName
        this.stabilityTestWorkPlanForm.planTime = res.planTime.split('至')
        this.stabilityTestWorkPlanForm.batchNo = res.batchNo
        this.stabilityTestWorkPlanForm.spec = res.spec
        this.stabilityTestWorkPlanForm.projectMethod = res.projectMethod
        this.stabilityTestWorkPlanForm.dept = res.dept
        this.stabilityTestWorkPlanForm.opinions = res.opinions
        this.stabilityTestWorkPlanForm.reviewResult = res.reviewResult
        this.stabilityTestWorkPlanForm.status = res.status
        this.stabilityTestWorkPlanForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectStabilityTestWorkPlanList(this.searchForm).then(res => {
        this.stabilityTestWorkPlanPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectStabilityTestWorkPlanList(this.searchForm).then(res => {
        this.stabilityTestWorkPlanPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectStabilityTestWorkPlanList(this.searchForm).then(res => {
        this.stabilityTestWorkPlanPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['STABILITY_TEST_DIRECTOR']) && row.status === 1) {
        return 'review'
      }
      return ''
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `稳定性试验工作计划${this.stabilityTestWorkPlanForm.planTime}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
